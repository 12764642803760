import React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../../components/Layouts/Layout';
import { OneColumn } from '../../components/Layouts/OneColumn';
import SEO from '../../components/Layouts/SEO';
import styles from './order.module.scss';

export function Head() {
  return (
    <>
      <SEO title="Ошибка оплаты" description="Во время оплаты заказа произошла ошибка" />
      <YandexTableau />
    </>
  );
}

function PaymentErrorPage() {
  return (
    <Layout>
      <OneColumn>
        <div className={styles.block}>
          <h1 className={cn({ [styles.header]: true, [styles.error]: true })}>
            Ой, ошибка оплаты!
          </h1>
          <p className={styles.description}>
            Мы получили ваш заказ и отправили подтверждение на почту. Попробуйте оплатить по ссылке
            в письме или&nbsp;
            <Link className={styles.link} to="/help">
              свяжитесь с нами
            </Link>
            &nbsp;любым удобным способом.
          </p>
        </div>
      </OneColumn>
    </Layout>
  );
}

export default PaymentErrorPage;
